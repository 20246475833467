import React from "react";
import { useTranslation } from "react-i18next";

import verifiedIcon from "../images/verified.png";

function EmailVerification() {
  const { t } = useTranslation();

  return (
    <section className="flex justify-center">
      <div className="pt-16 pb-20 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center">
          {t("Email Verification")}
        </h2>
        <div className="grid  gap-4 place-items-center h-40">
          <img
            alt="verified"
            src={verifiedIcon}
            className="max-w-52 max-h-36 px-2"
          />
        </div>
        <p className="mb-1 font-light text-center sm:text-base">
          {t("Thanks for verifying your email")}.
        </p>
        <p className="mb-8 lg:mb-16 font-light text-center sm:text-base">
          {t(
            "Your email was verified. You can now continue using the Fishingfinity App"
          )}
          .
        </p>
        <p style={{ fontSize: 10, textAlign: "center" }}>
          {t(
            "If you’re having trouble verifying your email please contact us at support@fishingfinity.com"
          )}
          .
        </p>
      </div>
    </section>
  );
}

export default EmailVerification;
