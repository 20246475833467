import React from "react";
import { useTranslation } from "react-i18next";
// import Gallery from "../../components/Gallery";

function AboutUs() {
  const { t } = useTranslation();
  return (
    <div className="mt-5 mx-auto max-w-screen-lg">
      {/* <div className="lg:hidden lg:block container">
        <Gallery />
      </div> */}

      <div className="p-3 mt-5 mx-auto max-w-screen-lg container">
        <p className="text-center text-xl md:text-2xl">
          {t("about fishingfinity")}
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
