import React, { useState, useEffect } from "react";
import Carousel from "better-react-carousel";
import { useTranslation } from "react-i18next";

import bgBot from "../../images/bg05.JPG";

import { ImagesIT } from "../../assets/androidSSIt";
import { ImagesEN } from "../../assets/androidSSEn";

const MyDot = ({ isActive }) => (
  <span
    style={{
      display: "inline-block",
      height: isActive ? "10px" : "5px",
      width: isActive ? "10px" : "5px",
      background: "#2dff03",
      borderRadius: 10,
    }}
  ></span>
);

function SlideShow() {
  const [datImg, setDatImg] = useState([]);

  const { i18n } = useTranslation();

  const lang = i18n.language;

  useEffect(() => {
    if (lang === "it") {
      setDatImg(ImagesIT);
    } else {
      setDatImg(ImagesEN);
    }
  }, [lang]);

  return (
    <div className="relative bg-neutral-500">
      <img
        src={bgBot}
        className="w-full h-full absolute mix-blend-overlay bg-contain"
        alt="bgBot"
      />
      <div className="py-10 px-2 rounded-2xl text-center mx-auto max-w-screen-xl container">
        <Carousel
          cols={1}
          rows={1}
          gap={10}
          showDots
          autoPlay={2000}
          loop={true}
          dot={MyDot}
          responsiveLayout={[
            {
              breakpoint: 2000,
              cols: 1,
              rows: 1,
              gap: 2,
              loop: true,
              autoplay: 2000,
            },
            {
              breakpoint: 1000,
              cols: 1,
              rows: 1,
              gap: 2,
              loop: true,
              autoplay: 2000,
            },
            {
              breakpoint: 500,
              cols: 1,
              rows: 1,
              gap: 2,
              loop: true,
              autoplay: 2000,
            },
          ]}
          mobileBreakpoint={350}
        >
          {datImg.map((item, i) => (
            <Carousel.Item key={item.id}>
              <div className="flex justify-center items-center">
                <img
                  src={item.img}
                  alt="img"
                  className="w-40 lg:w-60 md:w-50"
                />
              </div>
              {/* <div className="pb-3">
                <h5>{t(item.caption)}</h5>
              </div> */}
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default SlideShow;
