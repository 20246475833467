import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const activeStyle = ({ isActive }) => {
  return {
    fontWeight: isActive ? "bold" : "",
    color: isActive ? "#f20a0e" : "",
    fontSize: 14,
    textDecoration: isActive ? "underline" : "none",
  };
};

function MainMenu() {
  const { t } = useTranslation();
  return (
    <div className="hidden lg:block container">
      <div className="flex justify-center items-center space-x-4">
        <NavLink to="/" style={activeStyle}>
          Home
        </NavLink>
        {/* <NavLink
     to="https://partners-fishingfinity.web.app/"
     target="_blank"
     rel="noopener noreferrer"
     style={activeStyle}>
     Partnership
    </NavLink> */}
        <NavLink to="privacyAndPolicy" style={activeStyle}>
          {t("Privacy and Policy")}
        </NavLink>
        <NavLink to="termsAndConditions" style={activeStyle}>
          {t("Terms and Conditions")}
        </NavLink>
        <NavLink to="download-app" style={activeStyle}>
          {t("Download App")}
        </NavLink>
        <NavLink to="support" style={activeStyle}>
          {t("Support")}
        </NavLink>
      </div>
    </div>
  );
}

export default MainMenu;
