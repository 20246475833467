import React from 'react';

export default function ErrorPage() {
  return (
    <div className="grid grid-cols-1 text-center content-center h-screen px-20">
      <h1 className="font-bold text-gray-900">404 - File or directory not found.</h1>
      <h4> The resource you are looking for might have been removed, had its name changed, or is temporarily unavailable.</h4>
    </div>
  );
}
