import img01 from "./001.png";
import img02 from "./002.png";
import img03 from "./003.png";
import img04 from "./004.png";
import img05 from "./005.png";
import img06 from "./006.png";
import img07 from "./007.png";

export { img01, img02, img03, img04, img05, img06, img07 };

export const ImagesIT = [
  {
    id: "02",
    img: img02,
    title: "title 02",
    caption: "Connect and view friends post",
  },
  {
    id: "03",
    img: img03,
    title: "title 03",
    caption: "Log catches are organized by date",
  },
  {
    id: "04",
    img: img04,
    title: "title 04",
    caption: "7 days marine weather forecast",
  },
  {
    id: "05",
    img: img05,
    title: "title 05",
    caption: "Solunar and Tide data",
  },
  {
    id: "06",
    img: img06,
    title: "title 06",
    caption: "Temperature data",
  },
  {
    id: "07",
    img: img07,
    title: "title 07",
    caption: "Map",
  },
];
