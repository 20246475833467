import React from "react";
import Carousel from "better-react-carousel";

// import mkUp01 from "../images/mkUp01.png";
// import mkUp02 from "../images/mkUp02.png";
// import mkUp03 from "../images/mkUp03.png";
// import mkUp04 from "../images/mkUp04.png";
// import mkUp05 from "../images/mkUp05.png";

import mockUp3Images from "../assets/mockUp3Images.png";

const MyDot = ({ isActive }) => (
  <span
    style={{
      display: "inline-block",
      height: isActive ? "10px" : "7px",
      width: isActive ? "10px" : "7px",
      background: "#2dff03",
      borderRadius: 10,
    }}
  ></span>
);

export default function Gallery() {
  // const data = [
  //   { id: "01", img: mkUp01 },
  //   { id: "02", img: mkUp02 },
  //   { id: "04", img: mkUp04 },
  //   { id: "05", img: mkUp05 },
  //   { id: "03", img: mkUp03 },
  // ];

  return (
    // <div className="py-2 px-2 rounded-2xl text-center mx-auto max-w-screen-xl container">
    <div className="">
      {/* <img
        src={mockUp3Images}
        className="w-full h-full bg-contain"
        alt="mkUp01"
      /> */}
      <Carousel
        cols={1}
        rows={1}
        gap={0}
        showDots={false}
        loop={false}
        dot={MyDot}
        responsiveLayout={[
          {
            breakpoint: 3000,
            cols: 1,
            rows: 0,
            gap: 0,
            loop: false,
            autoplay: 2000,
          },
        ]}
        mobileBreakpoint={350}
        // responsiveLayout={[
        //   {
        //     breakpoint: 2000,
        //     cols: 2,
        //     rows: 1,
        //     gap: 2,
        //     loop: true,
        //     autoplay: 2000,
        //   },
        //   {
        //     breakpoint: 1000,
        //     cols: 2,
        //     rows: 1,
        //     gap: 2,
        //     loop: true,
        //     autoplay: 2000,
        //   },
        //   {
        //     breakpoint: 500,
        //     cols: 1,
        //     rows: 1,
        //     gap: 2,
        //     loop: true,
        //     autoplay: 2000,
        //   },
        // ]}
        // mobileBreakpoint={350}
        containerStyle={{ padding: 5, justify: "center", alignItems: "center" }}
      >
        <Carousel.Item>
          <div className="flex justify-center items-center">
            <img src={mockUp3Images} alt="img" className="w-full h-full" />
          </div>
        </Carousel.Item>
        {/* {data.map((item, i) => (
          <Carousel.Item key={item.id}>
            <div className="flex justify-center items-center">
              <img src={item.img} alt="img" className="w-40 lg:w-60 md:w-50" />
            </div>
          </Carousel.Item>
        ))} */}
      </Carousel>
    </div>
  );
}
