import React from "react";

import AboutUs from "./AboutUs";
import TopBanner from "./TopBanner";
import Features from "./Features";
import SlideShow from "./SlideShow";

function HomeScreen() {
  return (
    <div className="relative bg-neutral-600">
      <TopBanner />
      <div className="bg-waveBg bg-no-repeat bg-cover">
        <AboutUs />
        <Features />
      </div>
      <SlideShow />
    </div>
  );
}

export default HomeScreen;
