import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import DownloadBtn from "../components/DownloadBtn";

function MainFooter() {
  const { t } = useTranslation();

  const currYear = new Date().getFullYear();

  return (
    <footer
      className="text-center items-center justify-center"
      style={{
        background:
          "radial-gradient(circle at 5% 20%, rgb(0, 0, 0, 0.9) 0%, rgb(64, 64, 64) 90.2%)",
      }}
    >
      <div className="bg-blackWave bg-cover  bg-no-repeat">
        <div className="pt-10">
          <h4 className="mb-2 font-bold uppercase dark:text-white">
            {t("Download the app")}
          </h4>
          <DownloadBtn flexTo={true} />
        </div>

        <div className="py-5 grid grid-cols-3 gap-8 mx-auto max-w-screen-lg container">
          <div>
            <h6 className="mb-2 font-bold uppercase  dark:text-white">
              {t("Follow us")}
            </h6>
            <ul className=" flex justify-center">
              <li className="px-2">
                <Link
                  to="https://www.facebook.com/fishingfinity/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookSquare size={30} />
                </Link>
              </li>
              <li className="px-2">
                <Link
                  to="https://www.instagram.com/fishingfinity/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={30} />
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h6 className="mb-2 font-bold uppercase">{t("Terms of Use")}</h6>
            <ul className="">
              <li>
                <Link
                  to="privacyAndPolicy"
                  className="hover:underline hover:text-white text-xs sm:text-center "
                >
                  {t("Privacy and Policy")}
                </Link>
              </li>
              <li>
                <Link
                  to="termsAndConditions"
                  className="hover:underline hover:text-white text-xs sm:text-center "
                >
                  {t("Terms and Conditions")}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h6 className="mb-2 font-bold uppercase dark:text-white">
              {t("Support")}
            </h6>
            <ul className="">
              <li>
                <Link
                  to="support"
                  className="hover:underline hover:text-white text-xs sm:text-center "
                >
                  {t("Contact Us")}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t-2 border-grey-100 border-solid py-3 mx-auto max-w-screen-lg container md:items-center md:justify-between items-center">
          <span className="text-[10px] md:text-center text-center">
            Copyright © 2023-{currYear} Arbest S.N.C. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}

export default MainFooter;
