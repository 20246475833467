import { Outlet } from "react-router-dom";
import MainFooter from "../Footer";
import NavBar from "../Header/navBar";

const Root = () => {
  return (
    <div className="App bg">
      <main className="bg">
        <NavBar />
        <Outlet />
      </main>
      <div>
        <MainFooter />
      </div>
    </div>
  );
};

export default Root;
