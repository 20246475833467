import React from "react";
import {
  RouterProvider,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import HomeScreen from "./Pages/Home";
import PartnerScreen from "./Pages/Partner";
import SupportScreen from "./Pages/Support";
import PrivacyAndPolicyScreen from "./Pages/PrivacyAndPolicy";
import TermsAndConditionScreen from "./Pages/TermsAndCondition";
import AccountScreen from "./Pages/Account";
import ErrorPage from "./Pages/ErrorPage";
import Root from "./Pages/Root";
import Contact from "./Pages/Contact";
import QrCodeScreen from "./Pages/QrCode";
import EmailVerification from "./Pages/EmailVerification";
// import { SocialScreen } from './Pages'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index element={<HomeScreen />} />
      <Route path="partner" element={<PartnerScreen />} />
      <Route path="privacyAndPolicy" element={<PrivacyAndPolicyScreen />} />
      <Route path="termsAndConditions" element={<TermsAndConditionScreen />} />
      <Route path="account" element={<AccountScreen />} />
      <Route path="download-app" element={<QrCodeScreen />} />
      <Route path="support" element={<SupportScreen />}>
        <Route index element={<Contact />} />
      </Route>
      <Route path="*" element={<ErrorPage />} />
      <Route path="email-verification" element={<EmailVerification />} />
    </Route>
  )
);

function App() {
  return (
    <div className="bg">
      <RouterProvider router={router} />
    </div>
  );
}
export default App;
