import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  // BsArrowLeftCircle,
  BsArrowLeftCircleFill,
  // BsArrowRightCircle,
  BsArrowRightCircleFill,
} from "react-icons/bs";

// import backgroundTop from "../../images/tycoon.jpg";
// import newBackground from "../../assets/ale01.jpeg";
// import user005 from "../../assets/005.jpeg";
// import blackWave from "../../assets/blackWave.png";
import ale01 from "../../assets/ale01.jpeg";
import ale02 from "../../assets/ale02.jpeg";
import ale03 from "../../assets/ale03.jpeg";
import ste01 from "../../assets/ste01.jpeg";
import user001 from "../../assets/001.jpeg";
import user004 from "../../assets/004.jpeg";
import luca01 from "../../assets/luca01.jpeg";
import Gallery from "../../components/Gallery";
import DownloadBtn from "../../components/DownloadBtn";

export default function TopBanner() {
  const [photo, setPhoto] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (photo >= 8) {
        setPhoto(1);
        return;
      }
      setPhoto((prev) => prev + 1);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [photo]);

  const returnPhotoURL = () => {
    switch (photo) {
      case 1:
        return ale01;
      case 2:
        return luca01;
      case 3:
        return ale02;
      case 4:
        return ste01;
      case 5:
        return ale03;
      case 6:
        return user001;
      case 7:
        return user004;
      // case 8:
      //   return user005;
      default:
        return ale01;
    }
  };

  const handleOnClick = useCallback(
    (num) => {
      if (num === 0) {
        if (photo <= 0) {
          setPhoto(7);
        }
        setPhoto((prev) => prev - 1);
      } else {
        if (photo >= 8) {
          setPhoto(1);
        }
        setPhoto((prev) => prev + 1);
      }
    },
    [photo]
  );

  return (
    <div className="relative pb-4 bg-gradient-to-b from-sky-950 from-0% via-transparent via-30% justify-center items-center">
      <img
        src={returnPhotoURL()}
        className="w-full h-full bg-contain fixed mix-blend-overlay"
        alt="bgBot"
      />
      <div className="md:flex mainContainer justify-center items-center">
        <div className="mx-3 text-center">
          <h1 className="font-black text-4xl sm:text-6xl">
            {t("Sport Fishing App")}
          </h1>
          <h2 className="font-bold py-3">{t("Download the app")}</h2>
          <DownloadBtn flexTo={false} />
        </div>
        <Gallery />
      </div>
      <div className="flex absolute container justify-evenly content-end self-end">
        <button onClick={() => handleOnClick(0)} type="button">
          <BsArrowLeftCircleFill size={20} color="rgb(249 250 251)" />
        </button>
        <button onClick={() => handleOnClick(1)} type="button">
          <BsArrowRightCircleFill size={20} color="rgb(249 250 251)" />
        </button>
      </div>
    </div>
  );
}
