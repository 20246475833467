import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";

import logoHorizontal from "../images/LogoHorizontal.png";
import MobileMenu from "./mobileMenu";
import MainMenu from "./mainMenu";
import DropDownMenu from "../components/DropDownMenu";
import { useCallback } from "react";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [navSize, setnavSize] = useState("8rem");
  const [navColor, setnavColor] = useState("transparent");
  const [borderWidth, setborderWidth] = useState("0px");
  const [position, setposition] = useState("fixed");

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const listenScrollEvent = () => {
    window.scrollY > 10
      ? setnavColor("rgb(30 41 59)")
      : setnavColor("transparent");
    window.scrollY > 10 ? setnavSize("5rem") : setnavSize("8rem");
    window.scrollY > 10 ? setborderWidth("1px") : setborderWidth("0px");
    if (isOpen) {
      window.scrollY > 10 ? setposition("fixed") : setposition("relative");
    } else {
      setposition("fixed");
    }
  };

  const handleOpenMenu = useCallback(() => {
    // setIsOpen(!isOpen);
    if (isOpen) {
      setposition("fixed");
      setIsOpen(false);
    } else {
      setposition("relative");
      setIsOpen(true);
    }
  }, [isOpen]);

  function handleWindowSizeChange() {
    // setWidth(window.innerWidth);
    if (window.innerWidth >= 1024) {
      setposition("fixed");
      setIsOpen(false);
    }
  }

  // const isTransitioning = width <= 1023;
  // console.log("width", width, isTransitioning);

  return (
    <header>
      <nav
        style={{
          backgroundColor: isOpen ? "rgb(30 41 59)" : navColor,
          height: navSize,
          position: position,
          marginBottom: isOpen ? 50 : 0,
          borderBottomWidth: borderWidth,
          borderColor: "white",
          width: "100%",
          zIndex: 20,
          transition: "all 1s",
        }}
      >
        <div className="grid grid-flow-col pt-6 lg:px-8 px-4 justify-between items-center">
          <div className="lg:hidden">
            <button
              onClick={handleOpenMenu}
              type="button"
              className="inline-flex p-2 rounded-md text-white bg-gray-600 focus:outline-none"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
          <img className="max-h-7" src={logoHorizontal} alt="App Logo" />
          <MainMenu />
          <DropDownMenu />
        </div>

        <Transition
          show={isOpen}
          appear={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          afterLeave={() => setIsOpen(false)}
          beforeLeave={() => setIsOpen(false)}
        >
          <MobileMenu />
        </Transition>
      </nav>
    </header>
  );
}

export default NavBar;
