import React from "react";
import { FaUsers } from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { AiOutlineSafety } from "react-icons/ai";
import { useTranslation } from "react-i18next";

function Features() {
  const { t } = useTranslation();

  return (
    <div className="py-4 mx-auto max-w-screen-lg container">
      <h2 className="font-bold text-center pb-3">{t("FEATURES")}</h2>
      <div className="flex-none md:flex">
        <div className="p-2 container">
          <div className="flex items-center">
            <FaUsers size={45} color="#fefefe" />
            <h2 className="pl-2 text-center">{t("Connect and Share")}</h2>
          </div>
          <p className="pl-10">{t("Connect and Share details")}</p>
        </div>
        <div className="p-2 container">
          <div className="flex items-center">
            <TbListDetails size={45} color="#fefefe" />
            <h2 className="pl-2 text-center">{t("Log Catches")}</h2>
          </div>
          <p className="pl-10">{t("Log Catches details")}</p>
        </div>
      </div>

      <div className="flex-none md:flex">
        <div className="p-2 container">
          <div className="flex items-center">
            <TiWeatherPartlySunny size={45} color="#fefefe" />
            <h2 className="pl-2 text-center">{t("Marine Weather Forecast")}</h2>
          </div>
          <p className="pl-10">{t("Marine Weather Forecast details")}</p>
        </div>
        <div className="p-2 container">
          <div className="flex items-center">
            <AiOutlineSafety size={45} color="#fefefe" />
            <h2 className="pl-2 text-center">{t("Privacy")}</h2>
          </div>
          <p className="pl-10">{t("Privacy details")}</p>
        </div>
      </div>
    </div>
  );
}

export default Features;
